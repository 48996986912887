
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { ListComponentCellOptions } from '@/components/dataTables/cell/index'

@Component({
  components: {
    PriceCell: () => import('./PriceCell.vue'),
    StatusCell: () => import('./StatusCell.vue'),
    AlertCell: () => import('./AlertCell.vue'),
    RatingCell: () => import('./RatingCell.vue'),
    LinkedCell: () => import('./LinkedCell.vue'),
    ActionCell: () => import('./ActionCell.vue'),
    TextCell: () => import('./TextCell.vue'),
  },
})
  export default class ListComponentCell extends Cell {
  declare options: ListComponentCellOptions

  get items () {
    const { item } = this
    if (item instanceof Array) return item
  }

  get component () {
    const { options: { component } } = this
    return component
  }

  get cellOptions () {
    const { options: { options } } = this
    return options
  }

  get customCols () {
    const { options: { cols } } = this

    return cols || 12
  }

  get rowPx () {
    const { options: { row = null } } = this

    return row?.px
  }

  get rowPy () {
    const { options: { row = null } } = this

    return row?.py ? `py-${row.py}` : ''
  }

  get colPx () {
    const { options: { col = null } } = this

    return col?.px
  }

  get colPy () {
    const { options: { col = null } } = this

    return col?.py
  }

  get colPt () {
    const { options: { col = null } } = this

    return col?.pt ? `pt-${col.pt}` : ''
  }

  get isClickable () {
    const { options } = this
    return 'trigger' in options ? 'cursor: pointer' : ''
  }

  trigger (value) {
    const { options } = this
    if (!('trigger' in options)) return

    return options?.trigger(value)
  }
  }
